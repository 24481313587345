



 const reducer = (state = {}, action) => {
    switch (action.type) {
        case 'FRENCH': return {
            ...state,
            language: 'french'
        }
        case 'ENGLISH': return {
            ...state,
            language: 'english'
        }
        case 'SPANISH' : return {
            ...state,
            language: 'spanish'
        }
        case 'ADDCART' : return {
            ...state,
            cart: action.payload
        }
        default: return state
    }
}
export default reducer;







// const initialState = { counterData: 1 };

// const reducer = (state = initialState, action) => {
//     switch (action.type) {
//         case 'INCREMENT': return {
//             ...state,
//             counterData: action.payload + 2
//         }
//         case 'DECREMENT': return {
//             ...state,
//             counterData: action.payload -2
//         }
//         case 'RESET' : return {
//             ...state,
//             counterData: 0
//         }
//         default: return state
//     }
// }
// export default reducer;