import { createStore } from 'redux';
import Reducer from '../src/Reducer';


const initialState = {
    language: 'french',
    cart: [[0, 0], [1, 0]]
}

const store = createStore(Reducer, initialState);

export default store;


// import { createStore } from 'redux';
// import Reducer from '../src/Reducer';

// const initialState = {
//     counterData: 1
// }
// const store = createStore(Reducer, initialState);

// export default store;